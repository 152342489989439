.banner {
    background-image: url('https://shopic.mcmcclass.com/20221029142255884762504.jpg'); /* Dummy URL for the background image */
    background-size: cover;
    background-position: center;
    height: 250px; /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 8%;
  }
  
  .banner-content {
    text-align: center;
  }
  
  .banner h1 {
    font-size: 2.5em;
    margin: 0;
  }
  
  .underline {
    width: 150px;
    height: 3px;
    background-color: #370173;
    margin: 10px auto;
  }
  ::placeholder {
    color: gray;
    opacity:0.7; /* Firefox */
    font-style: italic;
  }
  