/* LoadingOverlay.css */
.loading-overlay {
    position: fixed;
    display: flex;
    flex-direction:  column;
    gap : 3px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  .loading-overlay p{
    color : #f3f3f3;
    font-weight: 500;
  }
  
  .spinner {
    border: 12px solid #f3f3f3; /* Light grey */
    border-top: 12px solid #3498db; 
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  