input {
  width: 100%;
  margin: 10px 0px;
  padding: 5px 2px;
  border-radius: 3px;
  font-weight: 500 !important;
  font-size: 18px !important;
}
a {
  text-decoration: none;
  color: black;
}
select {
  width: 100%;
  margin: 10px 0px;
  padding: 5px 2px;
  border-radius: 3px;
  font-weight: 500 !important;
  font-size: 18px !important;
  border: 1px solid #86b7fe !important;
}
textarea {
  width: 50%;
  margin: 10px 0px;
  padding: 5px 2px;
  border-radius: 3px;
  font-weight: 500 !important;
  font-size: 18px !important;
}
.icon {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.error {
  color: red !important;
  font-size: 12px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px dashed #86b7fe;
  padding: 40px 0px;
  height: 160px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.02);
}
.fille {
  width: 100%;
  height: 105px;
  cursor: pointer;
}
.btn {
  cursor: pointer;
  display: inline-block;
  background: #36017300 !important;
  color: #002341;
  border: 1px solid #002341 !important;
  padding: 8px 20px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.btn_sbmit {
  width: fit-content;
  padding: 13px 40px;
}
.btn:hover {
  color: #ffffff !important;
  background-color: #002341 !important;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.form-control {
  padding: 9px 10px !important;
  border: 1px solid #86b7fe !important;
  margin-bottom: 0px;
}
.form-control:focus {
  box-shadow: none !important;
}
select {
  padding: 9px 10px !important;
  border: 3px solid #86b7fe !important;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: auto !important;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
p {
  font-size: 18px;
  font-weight: 500;
}
.hp {
  margin-bottom: 0px;
}
.p1 {
  margin-top: 10px;
}
.p2 {
  margin-top: 10px;
}
#input_1_10 {
  width: 100%;
}
.footer-top {
  background-color: #2cbf7b;
  border-radius: 6px;
  padding: 20px 125px;
  position: absolute;
  top: -50px;
}
.footer {
  background-image: linear-gradient(#2cbf7b, #2cbf7b);
  background-position: bottom top;
  background-repeat: no-repeat;
  background-size: 100% 1%;
  margin-top: 50px;
  position: relative;
}

.bb-footer {
  background: white;
  padding: 13px 30px;
  border: 1px solid white;
  color: #000000;
  font-weight: 700;
}
.bb-footer:hover {
  background-color: #2cbf7b;
  border: 1px solid white;
}

.submt_div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.navbar-nav .ss::after {
  content: "|";
}

div:where(.swal2-container).swal2-backdrop-show, div:where(.swal2-container).swal2-noanimation {
  background: rgba(0, 0, 0, .8) !important;
}
@media (max-width: 991px) {
  #input_1_10 {
    width: 100%;
  }
  .navbar-nav .ss::after {
    content: "";
  }
}
@media (max-width: 767px) {
  #input_1_10 {
    width: 100%;
  }
  .footer-top {
    background-color: #2cbf7b;
    border-radius: 6px;
    padding: 16px 52px;
    position: absolute;
    top: -50px;
  }
  p {
    font-size: 16px;
  }
  select {
    font-size: 16px !important;
  }
  input {
    font-size: 16px !important;
  }
  textarea {
    font-size: 16px !important;
  }
  #dropdown-icon {
    right: 3%;
  }
}

/* .crossIcon{
  left: 60% !important;
} */

.agree_check_box {
  text-align: left !important;
}

.terms_condtions_text {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

@media (min-width: 1400px) {
  .fille {
    width: 100%;
    height: 90px;
    cursor: pointer;
  }
}
