
.modal-body p {
    font-size:  15px;
    color : gray;

}

.modal-body h6 {
    font-size:  17px;  
    font-weight: 700;  
    
}

.modal-header {
    background-color: #ffffff;
    color : #000000 !important;
    opacity: 1 !important;
}

