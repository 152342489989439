
/* 
.custom-slider .ant-slider-track {
    background-color: #d8e0f3; 
    height: 10px;
  }
  .custom-slider .ant-slider-handle {
    border-color: #d8e0f3; 
    background-color: red; 
    height: 10px;
  }
  
  .custom-slider .ant-slider-rail {
    background-color: #d8e0f3;
    height: 10px;
  }
  
  .custom-slider .ant-slider-dot {
    border-color: #4b8cdc; 
    width: 16px;
    height: 16px; 
    border : none;
    color : #385fb9
  }
   */
  .custom-slider .ant-slider-dot-active {
    border-color: #4b8cdc !important; 
    background-color: #4b8cdc !important; 
    width: 15px; 
    height: 15px; 
  }
  
  /* .custom-slider .ant-slider-mark-text {
    color: #4b8cdc; 
    font-size: 14px; 
    font-weight: bold; 
  }
  :where(.css-dev-only-do-not-override-3rel02).ant-slider .ant-slider-handle:hover::after, :where(.css-dev-only-do-not-override-3rel02).ant-slider .ant-slider-handle:active::after, :where(.css-dev-only-do-not-override-3rel02).ant-slider .ant-slider-handle:focus::after {
    box-shadow: 0 0 0 10px #1677ff;
    
    inset-inline-start: -1px;
    inset-block-start: -1px;
}
.custom-slider .ant-slider-handle {
  top: 5px;
}
:where(.css-dev-only-do-not-override-3rel02).ant-slider:hover .ant-slider-handle::after {
  box-shadow: 0 0 0 10px #1677ff !important;
}
:where(.css-dev-only-do-not-override-3rel02).ant-slider .ant-slider-handle::after{
  box-shadow: 0 0 0 10px #1677ff;
}
.custom-slider .ant-slider-track {
  background-color: #4B8CDC;
  height: 10px;
}
.custom-slider .ant-slider-rail {
  background-color: #b7c6d5;
  height: 10px;
}
 
:where(.css-dev-only-do-not-override-3rel02).ant-slider .ant-slider-handle::after {

    width: 16px !important;
    height: 16px !important;

}
:where(.css-dev-only-do-not-override-3rel02).ant-slider .ant-slider-handle::before {
  width: 16px !important;
  height: 16px !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-slider:hover .ant-slider-track {
  background-color: #4B8CDC;
}

 */
