label {
  text-align: center !important;
  width: 100%;
  color: rgb(0, 72, 108);
}
.container {
  /* margin-bottom:20px; */
  /* padding: 10px 30px; */
  border-radius: 6px;
}
