.contact-us-section {
  background-image: url("https://shopic.mcmcclass.com/20221029142255884762504.jpg"); /* Add the path to your background image */
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  margin-top: 80px;
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
}

.contact-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 16px;
  position: relative;
}
.middle_section {
  background: rgb(184 184 211 / 38%);
  padding: 1%;
  height: auto;
}
.contact-title::after {
  content: "";
  display: block;
  width: 23%;
  height: 5px;
  background-color: red;
  margin: 10px auto 0;
}

.contact-text {
  font-size: 1rem;
  margin-bottom: 20px;
}

.contact-call-to-action {
  font-size: 1.25rem;
  font-weight: bold;
}

.contact_content {
  background: aliceblue;
}
